import React, { useCallback, useState, useEffect, useRef } from 'react';
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import logo from './logo.svg';
import './App.css';


function App() {
  const providerRef = useRef();
  const [uri, setUri] = useState();
  const [account, setAccount] = useState();
  const [network, setNetwork] = useState();


  const onDeepClick = useCallback(() => {
    const deepLink = `metamask://wc?uri=${encodeURIComponent(uri)}`;
    window.location.href = deepLink;
  }, [uri]);

  const onUniversalClick = useCallback(() => {
    const universalLink = `https://metamask.app.link/wc?uri=${encodeURIComponent(uri)}`;
    window.location.href = universalLink;
  }, [uri]);

  useEffect(() => {
    // const provider = new WalletConnectV2({
    //   projectId: 'd2ef9380d5d17392dc3c33c476546702',
    //   // projectId: '0b4e7711ee048c2cb33f856221ef06a0',
    //   walletName: 'metamask',
    //   deepLinkPreference: 'native',
    // });
    // mmRef.current.on('display_uri', setUri);
    // // provider.init()
    // mmRef.current.init().then(() => {
    //   console.log(provider.getDeepLink());
    // });
    //
    EthereumProvider.init({
      projectId: 'd2ef9380d5d17392dc3c33c476546702',
      // projectId: '0b4e7711ee048c2cb33f856221ef06a0',
      // logger: 'warn',
      // chains: ['eip155:5','eip155:97', 'eip155:80001'],
      // chains: ['eip155:80001'],
      chains: [80001],
      showQrModal: false,
      metadata: {
        name: 'Dcentralab Wallet Connector',
        description: 'Custom connector for @dcentralab/web3-wc-modal',
        url: window.location.origin,
        icons: [],
      },
      client: undefined,
    })
      .then((provider) => {
        providerRef.current = provider;
        providerRef.current.on('display_uri', setUri);
        providerRef.current.on('connect', console.log);
        providerRef.current.on('accountsChanged', setAccount);
        providerRef.current.on('chainChanged', setNetwork);
        providerRef.current.enable();
    });
  }, []);

  console.log(`https://metamask.app.link/wc?uri=${uri}`);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Link
        </a>
        <button style={{ marginTop: 20, padding: 10 }} onClick={onDeepClick}>DeepLink</button>
        <button style={{ marginTop: 20, padding: 10 }} onClick={onUniversalClick}>UniversalLink</button>
        <p>Account: {account}</p>
        <p>Network: {network}</p>
      </header>
    </div>
  );
}

export default App;
